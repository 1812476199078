.company-card,
.card {
    background: #FFFFFF;
    box-shadow: 2px 4px 5px 3px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
}

.company-card {
    background: #FFFFFF;
    box-shadow: 2px 4px 5px 3px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    color: var(--secondaryHeadingColor);
}

.company-card h5,
.company-card p,
.card h5,
.card p {
    font-family: MuseoSans500;
}

.call-to-action {
    background-color: var(--callToActionBgColor);
    font-family: MuseoSans500;
}

.recommended-job {
    width: 93%;
    margin: 0 auto;
}

.recommended-job p {
    line-height: 22px;
}

.home-banner {
    background: linear-gradient(97.92deg, #589DAD 1.23%, #6BADBC 67.32%, #7CB3BF 96.95%);
    padding-right: 100px;
}

.swiper-slide {
    padding: 5px;

}

.footer {
    background-color: #143975;
}

.pt-32-5 {
    padding-top: 131px;
}

.pb-20-5 {
    padding-bottom: 90px;
}

@media only screen and (min-width:800px) {
    .toggler {
        display: none;
    }
}

@media only screen and (max-width:800px) {
    .header ul {
        display: none;
    }

    .header .absolute {
        left: 5% !important;
        width: 90% !important;
    }

    .toggler {
        display: block;
    }
}

@media only screen and (max-width:1200px) {
    .recommended-job {
        width: 100% !important;
    }
}

@media only screen and (max-width:767px) {
    .company-card h5 {
        font-size: 27px !important;
        line-height: 32px !important;
    }

    /* .home-banner .content {
        padding: 100px 0px !important;
    } */

    .company-card,
    .card {
        background: #FFFFFF;
        box-shadow: 2px 4px 5px 3px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
    }
}

.swiper-button-next.swiper-button-disabled::after,
.swiper-button-prev.swiper-button-disabled::after {
    color: #BDBDBD !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: #579CAC !important;
}

@media only screen and (max-width:1300px) {
    .home-banner {
        padding: 0px 25px;
    }
}

@media only screen and (max-width:1280px) {
    .pt-32-5 {
        padding-top: 40px !important;
    }

    .pb-20-5 {
        padding-bottom: 40px !important;
    }
}