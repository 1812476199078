.login-banner h1 {
    font-size: 50px;
    line-height: 60px;
}

.h-36-5 {
    min-height: 155px;
}

.rounded-3xl-5 {
    border-radius: 25px;
}

.w-3-12-5 {
    width: 30%;
}

@media only screen and (max-width:1450px) and (min-width:1201px) {
    .w-3-12-5 {
        width: 45% !important;
    }
}

@media only screen and (max-width:820px) and (min-width:670px) {
    .w-3-12-5 {
        width: 75% !important;
    }
}

@media only screen and (max-width:670px) {
    .w-3-12-5 {
        width: 100% !important;
        padding: 0px 25px !important;
    }
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 18px;
    z-index: 10;
    width: 18px;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    height: 20px;
    width: 20px;
    background-color: transparent;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input~.checkmark {
    background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked~.checkmark {
    background-color: var(--primaryHeadingColor) !important;
    border-color: var(--primaryHeadingColor) !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (max-width:1100px) {
    .rounded-3xl-5 {
        border-radius: 17px !important;
    }

    .h-36-5 {
        min-height: 100px !important;
    }
}

@media only screen and (max-width:600px) {
    .checkmark {
        height: 24px !important;
        width: 24px !important;
    }

    .custom-checkbox .checkmark:after {
        top: 4px !important;
        left: 8px !important;
    }
}