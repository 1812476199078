@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(./assets/css/home_page.css);
@import url(./assets/css/login_page.css);
@import url(./assets/css/signup_page.css);

html,
body,
body * {
  padding: 0;
  /* background-color: #f3f4f6 !important; */
  margin: 0;
  font-family: "Inter", sans-serif;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.loader-spinner {
  animation: rotation 1s infinite linear;
}

:root {
  --secondaryHeadingColor: #4F4F4F;
  --primaryHeadingColor: #143975;
  --primaryButtonColor: #FFB538;
  --callToActionBgColor: #579CAC;
  --callToActionTextColor: #54A8C4;
  --uploaderLogoColor: #C4C4C4;
  --tagsColor: #E1E1E1;

}

.tags-color {
  background-color: var(--tagsColor);
}

.uploader-bg-color {
  background-color: var(--uploaderLogoColor);
}

@font-face {
  font-family: MuseoSans700;
  src: url('./assets/fonts/museo_sans_ssv/MuseoSans700/MuseoSans_700.otf');
}

@font-face {
  font-family: MuseoSans300;
  src: url('./assets/fonts/museo_sans_ssv/MuseoSans300/MuseoSans-300.otf');
}

@font-face {
  font-family: MuseoSans500;
  src: url('./assets/fonts/museo_sans_ssv/MuseoSans500/MuseoSans_500.otf');
}

a {
  cursor: pointer;
}

.call-to-action-text-color {
  color: var(--callToActionTextColor);
}

.steps-main-border-color {
  border-color: var(--callToActionTextColor);
}

.google-btn-bg-color {
  background-color: var(--callToActionTextColor);
}

.main-container {
  max-width: 1300px;
  padding: 0px 25px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.footer-main-container {
  max-width: 1330px;
  padding: 0px 25px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.inner-container {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: block;
}

input::placeholder,
textarea::placeholder {
  color: var(--secondaryHeadingColor) !important;
  opacity: 1 !important;
}

.primary-heading-color {
  color: var(--primaryHeadingColor);
}

.secondary-heading-color {
  color: var(--secondaryHeadingColor);
}

.primary-bg-color {
  background-color: var(--primaryButtonColor);
}

.footer-main-container hr {
  border-color: #BDBDBD !important;
}

h1 {
  font-size: 70px;
  line-height: 84px;
}

h2 {
  font-size: 45px;
  line-height: 54px;
}

h3 {
  font-size: 35px;
  line-height: 42px;
}

h4 {
  font-size: 25px;
  line-height: 30px;
}

h5 {
  font-size: 20px;
  line-height: 24px;
}

h6 {
  font-size: 18px;
  line-height: 22px;
}

.text-6-5-xl {
  font-size: 55px;
  line-height: 66px;
}

.font-family-500 {
  font-family: MuseoSans500;
}

.font-family-300 {
  font-family: MuseoSans300;
}

.font-family-700 {
  font-family: MuseoSans700;
}

.steps {
  border-width: 3px;
  border-style: solid;
}

.secondary-border-color {
  border-color: var(--primaryButtonColor);
}

.secondary-link-color {
  color: var(--primaryButtonColor);
}

.call-to-action-color {
  color: var(--callToActionBgColor);
}

.px-22 {
  padding-left: 90px;
  padding-right: 90px;
}

.header .absolute {
  width: 96%;
  left: 2%;
}

@media only screen and (max-width:1000px) {

  .home-banner h1 {
    font-size: 40px !important;
    line-height: 48px !important;
  }

  h1,
  h2 {
    font-size: 30px !important;
    line-height: 36px !important;
  }

  h3 {
    font-size: 27px !important;
    line-height: 32px !important;
  }

  h4 {
    font-size: 25px !important;
    line-height: 30px !important;
  }

  h5 {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  h6 {
    font-size: 18px !important;
    line-height: 22px !important;
  }
}